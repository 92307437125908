import * as React from "react";
import Container from "../../components/container";
import Layout from "../../components/layout";
// import { StaticImage } from "gatsby-plugin-image";
// import HubspotForm from "react-hubspot-form";
import {
  mainContent,
  introSection,
  description,
  row,
  buttonGroup,
  // col6,
  col12,
  marginTopSubstitute,
  // hubSpotForm,
} from "../subpage.module.scss";

const TieTalkPage = () => {
  return (
    <Layout pageTitle="ti-e Talk Kassel 2025" neutralPage={true}>
      <main className={mainContent}>
        <section className={introSection}>
          <Container oneColumn={true}>
            <div className={row}>
              <div className={col12}>
                {/*   <p className={description}>Webinar mit Partner</p>
                <div style={{ marginBottom: 16 }}>
                  <StaticImage
                    src="../../assets/netcetera.png"
                    alt="Netcetera Logo"
                    placeholder="none"
                    layout="fixed"
                    formats={["auto", "webp", "avif"]}
                    width={140}
                  />
                </div> */}

                <div className={`${"background-sky-blue"} ${"text-blue"}`}>
                  <div style={{ margin: 16 }}>
                    <p className={description}>
                      No. 08 | Insight ti-e | Nov. 2024
                    </p>
                    <h1 className={marginTopSubstitute}>
                      Einladung zum ti-e Talk in Kassel
                    </h1>
                    <p className="text-medium">
                      Mi. 29. & Do. 30.01.25, im Hotel Schweizer Hof in Kassel{" "}
                    </p>
                  </div>
                </div>

                <div className={row}>
                  <div className={col12}>
                    <div className={"text-blue"} style={{ margin: 16 }}>
                      <p className={marginTopSubstitute}>Guten Tag,</p>

                      <p>
                        <span className="italic">
                          wir laden Sie herzlich zum ti-e Talk nach Kassel ein.
                          Der ti-e Talk ist ein halbjährliches Event und
                          Austauschformat für unsere Kunden, Interessenten und
                          Partner rund um die Themen Interoperabilität und
                          Digitalisierung im Krankenhaus.
                        </span>{" "}
                      </p>
                      <p>
                        Freuen Sie sich auf spannende Use-Case-Präsentationen
                        und Live-Demos unserer Kunden und Partner. Und nutzen
                        Sie die Gelegenheit zum Austausch und Networking mit den
                        Anwesenden. Wir stehen Ihnen diskret im Hintergrund zur
                        Verfügung und geben Ihnen Raum für Gespräche.
                      </p>

                      <div className={buttonGroup}>
                        <a
                          href="https://www.eventbrite.ch/e/ti-e-talk-kassel-tickets-1064104217359?aff=oddtdtcreator"
                          className="button blue text-sky-blue"
                          target="_blank"
                          rel="noreferrer"
                        >
                          Hier Anmelden
                        </a>
                      </div>
                    </div>
                  </div>
                </div>

                <div className={row}>
                  <div className={col12}>
                    <div className={`${"background-sky-blue"} ${"text-blue"}`}>
                      <div style={{ margin: 16 }}>
                        {/*  <StaticImage
                          src="../../assets/Mittel.jpeg"
                          alt="Bild von Computer mit DocDive"
                          placeholder="none"
                          layout="fullWidth"
                          formats={["auto", "webp", "avif"]}
                        /> */}
                        <h2>Tag 1 - Mittwoch 29.01.2025</h2>
                        <p>
                          {" "}
                          <span>Ab 12:00 Uhr (Foyer):</span> <br />{" "}
                          <span className="italic">
                            {" "}
                            Check-In und leichtes Mittagessen{" "}
                          </span>
                        </p>
                        <p>
                          {" "}
                          <span>13:00 Uhr (Tagungsraum):</span> <br />{" "}
                          <span className="italic">
                            {" "}
                            Begrüßung und Event Kick-Off{" "}
                          </span>
                        </p>
                        <p>
                          {" "}
                          <span>
                            13:15 - 14:30 Uhr: Praxisbeispiel Interoperabilität:{" "}
                          </span>{" "}
                          <br />{" "}
                          <span className="italic">
                            Kundenvortrag, Landeskrankenhaus Andernach{" "}
                          </span>
                        </p>
                        <p>
                          {" "}
                          <span>
                            14:30 - 15:15 Uhr: health-engine® und DocDive (KI):{" "}
                          </span>{" "}
                          <br />{" "}
                          <span className="italic">
                            Partnervortrag, Netcetera{" "}
                          </span>
                        </p>
                        <p>
                          {" "}
                          <span>
                            15:15 - 16:00 Uhr: Kaffeepause & Networking
                          </span>{" "}
                        </p>
                        <p>
                          {" "}
                          <span>
                            16:00 - 16:30 Uhr: KHZG und was kommt jetzt?
                          </span>{" "}
                          <br />{" "}
                          <span className="italic">
                            Expertenvortrag, Prof. Dr. Pierre-Michael Meier
                          </span>
                        </p>
                        <p>
                          {" "}
                          <span>
                            16:30 - 16:45 Uhr: Tagesabschluss{" "}
                          </span> <br />{" "}
                          <span className="italic">
                            Transfer zur Abendveranstaltung{" "}
                          </span>
                        </p>
                        <p>
                          {" "}
                          <span>
                            ab 17:15 Uhr: Abendveranstaltung (Optional)
                          </span>{" "}
                          <br />{" "}
                          <span className="italic">
                            Führung durch das Grimm Universum und Abendessen im
                            Restaurant Humboldt 1a.{" "}
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className={row}>
                  <div className={col12}>
                    <div className={`${"background-sky-blue"} ${"text-blue"}`}>
                      <div style={{ margin: 16 }}>
                        {/*  <StaticImage
                          src="../../assets/Mittel.jpeg"
                          alt="Bild von Computer mit DocDive"
                          placeholder="none"
                          layout="fullWidth"
                          formats={["auto", "webp", "avif"]}
                        /> */}
                        <h2>Tag 2 - Donnerstag 30.01.2025</h2>
                        <p>
                          {" "}
                          <span>08:45 Uhr (Tagungsraum):</span> <br />{" "}
                          <span className="italic">
                            {" "}
                            Treffpunkt und Vorbereitung{" "}
                          </span>
                        </p>
                        <p>
                          {" "}
                          <span>
                            09:00 - 10:30 Uhr: Praxisbeispiel Patientenportal:
                          </span>{" "}
                          <br />{" "}
                          <span className="italic">
                            {" "}
                            Kundenvortrag, Gesundheitsholding Lüneburg
                          </span>
                        </p>
                        <p>
                          {" "}
                          <span>
                            10:30 - 11:00 Uhr: Kaffeepause & Networking
                          </span>{" "}
                        </p>
                        <p>
                          {" "}
                          <span>
                            11:00 - 11:30 Uhr: Neues zur health-engine®
                          </span>{" "}
                        </p>
                        <p>
                          {" "}
                          <span>
                            11:30 - 11:45 Uhr: Abschluss & Verabschiedung
                          </span>{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className={row}>
                  <div className={col12}>
                    <div className={"text-blue"} style={{ margin: 16 }}>
                      <p>
                        Bitte melden Sie sich bis spätestens{" "}
                        <span className="text-purple">
                          Freitag, den 11. Dezember 2024{" "}
                        </span>
                        an. Die Plätze sind begrenzt und werden nach dem
                        First-Come-First-Served-Prinzip vergeben.
                      </p>

                      <p>
                        Für Verpflegung vor Ort und bei der Abendveranstaltung
                        ist gesorgt. Reise- und Übernachtungskosten sind von den
                        Teilnehmern selbst zu tragen und zu organisieren.
                      </p>

                      <p>
                        Bis zum <span className="text-purple">18.12.24 </span>
                        steht im Hotel Schweizer Hof in Kassel{" "}
                        <span className="text-purple">
                          ein Zimmerkontingent zu reduzierten Preisen zur
                          Verfügung.
                        </span>{" "}
                        Stichwort: "i-engineers"
                      </p>

                      <div className={buttonGroup}>
                        <a
                          href="https://www.eventbrite.ch/e/ti-e-talk-kassel-tickets-1064104217359?aff=oddtdtcreator"
                          className="button blue text-sky-blue"
                          target="_blank"
                          rel="noreferrer"
                        >
                          Hier Anmelden
                        </a>
                      </div>

                      <p className={marginTopSubstitute}>
                        Wir hoffen, Sie bald in Kassel begrüßen zu dürfen und
                        freuen uns auf spannende Gespräche.
                      </p>
                      <p>Mit besten Grüßen, ihr Team von the i-engineers</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Container>
        </section>
      </main>
    </Layout>
  );
};

export default TieTalkPage;
